export const INCOTERM_VALUES = [
  {
    id: 'CFR',
    name: 'CFR - Koszt i fracht',
  },
  {
    id: 'CIF',
    name: 'CIF - Koszt, ubezpieczenie i fracht',
  },
  {
    id: 'CIP',
    name: 'CIP - Przewoźne i ubezpieczenie opłacone do',
  },
  {
    id: 'CPT',
    name: 'CPT - Przewoźne opłacone do',
  },
  {
    id: 'DAF',
    name: 'DAF - Dostarczone na granicę',
  },
  {
    id: 'DAP',
    name: 'DAP - Dostarczone do miejsca',
  },
  {
    id: 'DAT',
    name: 'DAT - Dostarczone do terminala',
  },
  {
    id: 'DDP',
    name: 'DDP - Dostarczone, cło opłacone',
  },
  {
    id: 'DDU',
    name: 'DDU - Dostarczone, cło nieopłacone',
  },
  {
    id: 'DEQ',
    name: 'DEQ - Dostarczone na nabrzeże',
  },
  {
    id: 'DES',
    name: 'DES - Dostarczone na statek',
  },
  {
    id: 'EXW',
    name: 'EXW - Od zakładu',
  },
  {
    id: 'FAS',
    name: 'FAS - Franco wzdłuż burty statku',
  },
  {
    id: 'FCA',
    name: 'FCA - Franco przewoźnik',
  },
  {
    id: 'FOB',
    name: 'FOB - Franco statek',
  },
  {
    id: 'DPU',
    name: 'DPU - Dostarczone na miejsce rozładunku',
  },
];
