<template>
  <div>
    <form-section :title="$t('courierSet.authorizationData.title')">
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.login"
            :title="$t('courierSet.authorizationData.login')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getAuthorizationData.password"
            :title="$t('courierSet.authorizationData.password')"
            :rules="isNew ? 'required' : ''"
            type="password"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.accessKey"
            :title="$t('courierSet.authorizationData.accessKey')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getAuthorizationData.url"
            :title="$t('courierSet.authorizationData.urlAddress')"
            rules="required"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.shipmentMethod.title')">
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.configurationName"
            :title="$t('courierSet.shipmentMethod.configurationName')"
            rules="required|not_custom_shipment"
          />
        </v-col>
        <v-col>
          <select-field
            v-model="getAuthorizationData.courierCode"
            :title="$t('courierSet.shipmentMethod.deliverySpeditor')"
            :filed-items="SHIPMENT_METHOD_CODES"
            rules="required"
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.id"
            :title="$t('courierSet.shipmentMethod.configurationId')"
            rules="required"
            disabled
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.serviceType.title')">
      <v-row>
        <v-col>
          <select-field
            v-model="getDefaultValues.incoterm"
            :title="$t('courierSet.serviceType.incoterms')"
            :filed-items="INCOTERM_VALUES"
            rules="required"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import TextField from '@/components/inputs/TextField.vue';
import FormSection from '@/components/shared/FormSection.vue';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';
import { INCOTERM_VALUES } from '@/constants/Speditors/DBSchenkerInternational/IncotermValues.js';

export default Vue.extend({
  components: {
    TextField,
    FormSection,
  },
  props: {
    isNew: { type: Boolean, default: false },
  },
  data: () => ({ SHIPMENT_METHOD_CODES, INCOTERM_VALUES }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getAuthorizationData() {
      return this.getConfigurationSet();
    },
    getDefaultValues() {
      return this.getConfigurationSet().defaultValues;
    },
  },
});
</script>
