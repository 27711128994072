export const labelReferenceTypes = [
  {
    id: 'DWB',
    name: 'Numer listu przewozowego',
  },
  {
    id: 'COR',
    name: 'Numer referencyjny zamówienia klienta',
  },
  {
    id: 'ZDNNO',
    name: 'Numer Zbiorczego dokumentu nadania',
  },
];
