import { DBSchenkerInternationalDefaultValues } from './DBSchenkerInternationalDefaultValues';

export const DBSchenkerInternational = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'DBSchenkerInternational',
  integration: 'DBSchenkerInternational',
  skipShipmentGeneration: false,
  requireLabel: false,
  labelSource: 'NUBOWMS',
  additionalServices: {
    services: [],
  },
  login: '',
  accessKey: '',
  clientNumber: '',
  url: '',
  groupId: 0,
  shipperAddressId: 0,
  shipperVatNo: '',
  productCode: '43',
  labelReferenceType: '',
  labelType: 'A6',
  freightType: 'Land',
  defaultValues: DBSchenkerInternationalDefaultValues,
};
