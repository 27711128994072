export const DBSchenkerInternationalDefaultValues = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  measurementType: 'METRIC',
  cargoDescription: '',
  incoterm: '',
  incotermLocation: '',
  insurance: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    amount: 0,
    currency: '',
  },
};
